<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.SALE.COMPLETED_JOBS_WITHOUT_FK') }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="list"
              single-select
              @click:row="rowClick"
              item-key="id"
              no-data-text="Maʼlumot kiritilmagan"
              class="elevation-1 row-pointer fixed-header"
            >
              <template v-slot:[`item.contract`]="{ item }">
                <router-link :to="'/newcontractdetails/' + item.contract">
                  {{ item.contract_number }}
                </router-link>
              </template>
              <template v-slot:[`item.start_date`]="{ item }">
                {{ item.start_date | formatDate }}
              </template>
              <template v-slot:[`item.direction`]="{ item }">
                <!-- {{ item }} -->
                <span
                  v-for="(dest, index) in item.direction_cities"
                  :key="dest.id"
                >
                  {{ dest.city_name.name }}
                  <span v-if="index == item.direction_cities.length - 1"></span>
                  <span v-else>-</span>
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <Action :index="item"></Action>
              </template>
            </v-data-table>
            <div class="row justify-content-end mt-2">
              <div class="mr-4">
                <p
                  style="font-size: 15px"
                  class="
                    text-dark
                    font-bold
                    label label-lg label-light-success label-inline
                  "
                >
                  Soni: {{ list.length }}
                </p>
              </div>
              <div>
                <p
                  style="font-size: 15px"
                  class="
                    text-dark
                    font-bold
                    label label-lg label-light-success label-inline
                  "
                >
                  Umumiy Summa:{{ total }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const data = [1, 2, 3, 4, 5]
data.slice(1, 2)
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Action from './Action'
export default {
  data() {
    return {
      total: 0,
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      currentPage: 1
    }
  },
  created() {
    this.$store.dispatch('getDeliveredOutfacture')
  },
  watch: {
    list: function (val) {
      this.total = 0
      if (val) this.total = val.map((x) => x.price).reduce((a, b) => a + b, 0)

      this.total = this.total.toLocaleString('es-US')
      val.forEach((x) => (x.price = x.price.toLocaleString('es-US')))
      // val.price = val.price.price.toLocaleString('es-US')
    }
  },
  computed: {
    headers() {
      return [
        { text: 'Id', value: 'id' },
        { text: this.$t('TABLE_HEADER.CLIENT'), value: 'zayavka_owner' },
        { text: this.$t('TABLE_HEADER.CONTRACT_NUMBER'), value: 'contract' },
        { text: this.$t('TABLE_HEADER.PROVIDER'), value: 'deliver' },
        { text: this.$t('TABLE_HEADER.PRICE'), value: 'price' },
        { text: 'Boshlanishi', value: 'start_date' },
        { text: this.$t('TABLE_HEADER.DIRECTION'), value: 'direction' },
        { text: '', value: 'action', sortable: false }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    list() {
      const data = this.$store.state.requests.deliveredOutfacture
      data.forEach((element) => {
        // element.price = element.price.toLocaleString('es-US')
        if (element.start_date)
          element.start_date = element.start_date.slice(0, 10)
      })
      return data
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('BREADCRUMBS.SOLD') },
      { title: this.$t('MENU.SALE.COMPLETED_JOBS_WITHOUT_FK') }
    ])
  },
  components: {
    Action
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  min-height: 300px;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  overflow-y: unset !important;
}
.v-application .fixed-header {
  height: auto !important;
  overflow-y: unset !important;
}
</style>
